import React, {useState} from "react";
import { Waypoint } from "react-waypoint";
import { useSpring, animated } from "@react-spring/web";

interface TopProps {
  delay?: number;
  movePx?: number;
}

export default function MoveY({ children, delay = 400, movePx = 24 }: React.PropsWithChildren<TopProps>) {
  const [inView, setInView] = useState(false);

  const transition = useSpring({
    delay,
    to: {
      y: !inView ? movePx : 0,
    }
  })

  return (
    <Waypoint onEnter={() => setInView(true)}>
      <animated.div style={transition}>
        {children}
      </animated.div>
    </Waypoint>
  )
}