import React from "react";
import Box from "@mui/material/Box";
import {StaticImage} from "gatsby-plugin-image";

import appLeftMobile from "assets/images/app-left@250w.png";
import appLeftDesktop from "assets/images/app-left@500w.png";
import appLeftDesktopHQ from "assets/images/app-left@1000w.png";
import appRightMobile from "assets/images/app-right@250w.png";
import appRightDesktop from "assets/images/app-right@500w.png";
import appRightDesktopHQ from "assets/images/app-right@1000w.png";
import MoveY from "components/animations/MoveY";

export default function Picture() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: "auto"
      }}
    >
      <div style={{ width: "44%" }}>
        <MoveY delay={750} movePx={64}>
          <StaticImage src="../../assets/images/app-left@500w.png" alt="mobile app 1" style={{ width: "100%" }}  loading="lazy" />
        </MoveY>
      </div>
      <div style={{width: "44%" }}>
        <MoveY delay={750} movePx={-64}>
          <StaticImage src="../../assets/images/app-right@500w.png" alt="mobile app 1" style={{ width: "100%" }} loading="lazy" />
        </MoveY>
      </div>
    </Box>
  )
}